<template>
  <b-row class="match-height">

    <b-col cols="12">
      <b-card no-body>
        <b-card-header>
          <b-container>
            <b-row>

              <!-- Klient -->
              <b-col
                cols="12"
                xl="3"
                md="3"
              >

                <b-form-group>
                  <v-select
                    placeholder="Klient"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="filterClient"
                  />
                </b-form-group>

              </b-col>
              <!-- Klient -->

              <!-- Typ -->
              <b-col
                cols="12"
                xl="3"
                md="3"
              >

                <b-form-group>
                  <v-select
                    placeholder="Typ dokumentu"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="filterType"
                  />
                </b-form-group>

              </b-col>
              <!-- Typ -->

              <!-- Status-->
              <b-col
                cols="12"
                xl="3"
                md="3"
              >

                <b-form-group>
                  <v-select
                    placeholder="Status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="filterStatus"
                  />
                </b-form-group>

              </b-col>
              <!-- Status -->

              <!-- button -->
              <b-col
                cols="12"
                xl="3"
                md="3"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                >
                  Zastosuj filtry
                </b-button>
              </b-col>
              <!-- button -->

            </b-row>

            <b-row>

              <!-- Płatność-->
              <b-col
                cols="12"
                xl="3"
                md="3"
              >

                <b-form-group>
                  <v-select
                    placeholder="Płatność"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="filterBilling"
                  />
                </b-form-group>

              </b-col>
              <!--Płatność -->

              <!-- Przedział czasowy -->
              <b-col
                cols="12"
                xl="3"
                md="3"
              >

                <div class="d-flex align-items-center">
                  <span class="title pr-1">
                    Od:
                  </span>
                  <flat-pickr
                    class="form-control invoice-edit-input"
                  />
                  <span class="title pr-1 pl-1">
                    Do:
                  </span>
                  <flat-pickr
                    class="form-control invoice-edit-input"
                  />
                </div>

              </b-col>
              <!-- Przedział czasowy -->

              <!-- Przedział cenowy-->
              <b-col
                cols="12"
                xl="3"
                md="3"
              >

                <b-form-group>
                  <v-select
                    placeholder="Przedział cenowy"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="filterBilling"
                  />
                </b-form-group>

              </b-col>
              <!--Przedział cenowy -->

            </b-row>
          </b-container>

        </b-card-header>

      </b-card>
    </b-col>

    <b-col cols="12">
      <CostsLineChart />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
  BButton,
  BContainer,
  BCard,
  BCardBody,
  BCardHeader,
  BFormGroup,
  BCardTitle,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import CostsLineChart from './CostsLineChart.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BContainer,
    BCard,
    BCardBody,
    BCardHeader,
    BFormGroup,
    flatPickr,
    BCardTitle,
    vSelect,
    CostsLineChart,
  },
  data() {
    return {

      filterClient: [{ title: 'Jan Nowak' }, { title: 'Example comapny' }, { title: 'Example company 2' }],
      filterType: [{ title: 'Faktura VAT' }, { title: 'Proforma' }, { title: 'Marża' }],
      filterStatus: [{ title: 'Wystawiona' }, { title: 'Opłacona' }, { title: 'Nieopłacona' }],
      filterBilling: [{ title: 'PayPall' }, { title: 'Konto Bankowe' }, { title: 'Gotówka' }],

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
